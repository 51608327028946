<template>
  <main id="content" class="main">
    <section class="visual_intro">
      <div class="visual_wall"
           :style="{ backgroundImage: 'url(' + requireAssetsImg('img_intro.jpg')  + ')' }"
       ></div>
      <h1 class="visual_title" aria-label="DREAM PLUS">
        <div class="title_line">
          <span class="title letter_d">D</span>
          <span class="title letter_r">R</span>
          <span class="title letter_e">E</span>
          <span class="title letter_a">A</span>
          <span class="title letter_m">M</span>
        </div>
        <div class="title_line">
          <span class="title letter_p">P</span>
          <span class="title letter_l">L</span>
          <span class="title letter_u">U</span>
          <span class="title letter_s">S</span>
        </div>
      </h1>
      <div class="visual_video">
        <video width="210" autoplay muted>
          <source src="https://d2vivyykf0eqa3.cloudfront.net/media/logo-motion.mp4" type="video/mp4">
        </video>
      </div>
    </section>
    <section class="report_group">
      <!-- DREAMPLUS -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">DREAMPLUS</h2>
          <p class="description" v-html="$t('content.dreamplus.common.dreamplus_desc')">
          </p>
        </div>
        <StaggerTransition
          id="short-term-dreamplus"
        >
          <div>
            <ul class="brand_list">
              <li class="brand_item">
                <div class="brand_value">
                  <strong class="name">MISSION</strong>
                  <p class="info">Connect and Realize<br>Dreams</p>
                </div>
                <img :src="requireAssetsImg('img_mission.png')" width="372" height="342" class="img_brand">
              </li>
              <li class="brand_item">
                <div class="brand_value">
                  <strong class="name">ESSENCE</strong>
                  <p class="info">Facilitator for Open Innovation<br>and Realizing Dreams</p>
                </div>
                <img :src="requireAssetsImg('img_essence.png')" width="372" height="342" class="img_brand">
              </li>
              <li class="brand_item">
                <div class="brand_value">
                  <strong class="name">SLOGAN</strong>
                  <p class="info">Connecting Dreams</p>
                </div>
                <img :src="requireAssetsImg('img_slogan.png')" width="372" height="342" class="img_brand">
              </li>
            </ul>
          </div>
        </StaggerTransition>
        <div class="brand_cover">
          <div class="logo"><div class="ico_logo">DREAMPLUS</div></div>
          <div class="description">
            <strong class="main">SIGNATURE</strong>
            <p class="sub" v-html="$t('content.dreamplus.common.signature_desc01')">
            </p>
            <p class="sub" v-html="$t('content.dreamplus.common.signature_desc02')">
            </p>
          </div>
        </div>
        <div class="shortcut_area">
          <a id="brichureLink" href="javascript:void(0);" target="_blank" style="display: none;"></a>
          <a href="javascript:void(0);" role="button"
             @click.prevent="showEbook()"
             class="btn_shortcut brochure" v-html="$t('content.dreamplus.common.btn_brochure')">
          </a>
          <a href="javascript:void(0);" role="button"
             @click.prevent="downloadWMAArea()"
             class="btn_shortcut" v-html="$t('content.dreamplus.common.btn_brochure_area')">
          </a>
        </div>
      </section>
      <!-- // DREAMPLUS -->

      <!-- CONNECTING INNOVATION ECOSYSTEM -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">CONNECTING<br>INNOVATION ECOSYSTEM</h2>
          <p class="description" v-html="$t('content.dreamplus.common.innovation_ecosystem_desc')">
          </p>
        </div>
        <div class="ecosystem_diagram">
          <img :src="requireAssetsImg('img_ecosystem.jpg')"
               width="800"
               height="650"
               alt="dreamplus connecting innovation ecosystem"
               class="img_ecosystem">
        </div>
      </section>
      <!-- // CONNECTING INNOVATION ECOSYSTEM -->

      <!-- VALUE WE PROVIDE -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">VALUE WE PROVIDE</h2>
        </div>
        <StaggerTransition
          id="short-term-provide"
        >
          <ul class="supply_list">
            <li class="supply_item">
              <div class="supply center">
                <strong class="title">DREAMPLUS CENTERS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.centers_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item">
              <div class="supply accelerating">
                <strong class="title">ACCELERATING PROGRAMS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.programs_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item">
              <div class="supply network">
                <strong class="title">NETWORKING & OPEN PARTNERSHIP</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.partnership_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item">
              <div class="supply investment">
                <strong class="title">INVESTMENT & COLLABORATION</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.collaboration_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item">
              <div class="supply academy">
                <strong class="title">ACADEMY & EVENTS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.events_desc')">
                </p>
              </div>
            </li>
          </ul>
        </StaggerTransition>
      </section>
      <!-- // VALUE WE PROVIDE -->

      <!-- OPEN PARTNERSHIP -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">OPEN PARTNERSHIP</h2>
          <p class="description" v-html="$t('content.dreamplus.common.partnership_desc')">
          </p>
        </div>
        <div class="partner_area">
          <div class="partner_tab" role="tablist">
            <a href="javascript:void(0);" v-for="(tab, index) in tabList" :key="index"
               class="tab"
               role="tab"
               :aria-selected="index === tabValue ? 'true' : ''"
               @click="tabCliek(index)"
            >{{tab}}</a>
          </div>
          <div class="partner_swiper">
            <div class="partner_list">
              <StaggerTransition targets=".card-wrap">
                <GroupedSlider
                  ref="swiperSlider"
                  :list="openPartnership.SwiperList[tabValue]"
                  :pagination="true"
                  :autoplay="false"
                  :loop="false"
                  :itemsPerGroup="18"
                >
                  <template v-slot="{ item }">
                    <div class="partner" :class="{ 'hide': item.img ===''} ">
                      <div class="card-wrap">
                        <div class="card">
                          <img v-if="item.img" :src="requireAssetsImg(`partnership/${item.img}`)"
                                :alt="item.title" width="140" height="110"/>
                          <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </GroupedSlider>
              </StaggerTransition>
            </div>
          </div>
        </div>
      </section>
      <!-- // OPEN PARTNERSHIP -->
    </section>
    <section class="util_group">
      <div class="img_award">
        <img src="~@/assets/img/img_logo_reddot.png" width="153" height="102">
      </div>
    </section>
  </main>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import GroupedSlider from '@/components/slider/NewGroupedSlider.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import { FilePaths } from '@/common/portalApi';
import CoronaEvent from '@/components/popup/PopupCoronaEvent.vue';
import PopupApply from '@/components/popup/PopupApplyProgram.vue';
import HackathonEvent from '@/components/popup/PopupHackathonEvent.vue';
import openPartnership from '@/views/dreamplus/openPartnerShip';

import PopupList from '@/components/popup/PopupList.vue';
import { APIs } from '@/common/portalApi';

export default {
  name: 'DreamPlus',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    GroupedSlider,
    StaggerTransition,
  },
  data() {
    return {
      openPartnership,
      tabValue: 0,
      position: 0,
      tabList: [this.$t('content.dreamplus.common.btn_domestic'), this.$t('content.dreamplus.common.btn_global')],
      downloadFileEng: 'DREAMPLUS_Brochure_Eng.pdf', // %5BDREAMPLUS%5DBrochure_Eng.pdf
      downloadFileKor: 'DREAMPLUS_Brochure_Kor.pdf', // %5BDREAMPLUS%5DBrochure_Kor.pdf
      downloadFileArea: 'DREAMPLUS_Catalog.pdf',
      popupList: []
    };
  },
  methods: {
    tabCliek(i) {
      this.tabValue = i;
      this.$refs.swiperSlider.$el.swiper.slideTo(0);
    },
    downloadWWA() {
      let fileName = this.downloadFileKor;
      if (this.$i18n.locale === 'en') {
        fileName = this.downloadFileEng;
      }
      const path = this.getFileURL(FilePaths.ETC, fileName);
      this.downloadFile(path, fileName);
    },
    showEbook() {
      let type = FilePaths.BROCHURE_KO;
      if (this.$i18n.locale === 'en') {
        type = FilePaths.BROCHURE_EN
      }
      const path = `/dreamplus${type}`;
      const link = document.getElementById('brichureLink');
      link.setAttribute('href', path);
      link.click();
    },
    downloadWMAArea() {
      let fileName = this.downloadFileArea;
      if (this.$i18n.locale === 'en') {
        fileName = this.downloadFileArea;
      }
      const path = this.getFileURL(FilePaths.ETC, fileName);
      this.downloadFile(path, fileName);
    },
    /**
     * 코로나 팝업
     */
    showCoronaEvent(opt) {
      this.showPopup(CoronaEvent, 'corona-event', opt);
    },
    /**
     * 공모전 팝업
     */
    showPopupApply(opt) {
      this.showPopup(PopupApply, 'popup-apply', opt);
    },
    showHackerEvent(opt) {
      this.showPopup(HackathonEvent, 'hackathon-event', opt);
    },
    async getPopupConfig() {
      this.portalApiClient({
        url: APIs.POPUP_CONFIG,
        method: 'get',
        data: {},
      }).then(({ data }) => {
        if (data.result) {
          if (data.list) {
            for (let i = 0; i <data.list.length; i++) {
              const config = data.list[i].type
              if (config === 0) {
                this.position = data.list[i].position
                console.log('this.position', config, this.position)
                this.getPopupList();
              }
            }
          }
        }
      });
    },
    getPopupList() {
      this.portalApiClient({
        url: APIs.POPUP_LIST,
        data: {
          data: {
            type: 0 // portal
          }
        },
      }).then(({ data }) => {
        if (data.result) {
          this.popupList = data.list;
          console.log(this.$cookies.get('pop-list'))
          if (this.popupList && this.popupList.length > 0 && !this.$cookies.get('pop-list')) {
            setTimeout(() => {
              this.showPopup(PopupList, 'pop-list', { popupList: this.popupList, position: this.position});
            }, 0);
          }
        }
      });
    },
  },

  mounted() {
    const visibleCorona = this.$moment().isAfter('20201005 0000') && this.$moment().isBefore('20200130 2359')
      && this.$cookies.get('pop-corona') !== 'true';
    if (visibleCorona) {
      setTimeout(() => {
        this.showCoronaEvent(visibleCorona);
      }, 0);
    }
    const visiblePopupApply = this.$moment().isAfter('20201201 0000') && this.$moment().isBefore('2020131 2359')
      && this.$cookies.get('pop-apply') !== 'true';
    if (visiblePopupApply) {
      setTimeout(() => {
        this.showPopupApply(visiblePopupApply);
      }, 0);
    }
    this.getPopupConfig()
  },
  destroyed() {
    this.hidePopup('hackathon-event');
  },
};
</script>
<style>
.popup-wrap.hackathon_banner .popup{
  padding: 0;
  width: 600px;
}
.popup-wrap.hackathon_banner .popup .btn-close{
  background: none;
  text-indent: 0;
  cursor: pointer;
}
.popup-wrap.hackathon_banner .btn-close:before,
.popup-wrap.hackathon_banner .btn-close:after{
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 22px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  margin: -11px 0 0 -1px;
}
.popup-wrap.hackathon_banner .btn-close:after{
  transform: rotate(-45deg);
}
.popup-wrap.hackathon_banner .popup-contents img{
  width: 100%;
}
.popup-wrap.hackathon_banner .checkbox-container{
  margin: 10px;
  text-align: right;
}
.img_ecosystem {
  height: auto;
  width: 100%;
  margin-top: -100px;
  margin-bottom: -100px;
}
.btn_shortcut.brochure {
  margin-right: 32px;
}
</style>
